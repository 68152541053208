import { popup } from './popup';
import { POPUP_TYPE, POPUP_MSG } from '../constants';
export const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;
let request;

export const createDataBaseInIndexedDb = () => {
  //check for support
  if (!idb) {
    popup(POPUP_TYPE.ERR, POPUP_MSG.INDEX_DB_NOT_SUPPORTED);
    return;
  }

  request = idb.open('shakaWear-db', 1);

  request.onerror = function (event) {
    console.error(event);
  };

  request.onupgradeneeded = function (event) {
    if (event.isTrusted) {
      const db = request?.result;

      if (!db.objectStoreNames.contains('ShipmentData')) {
        const objectStore = db.createObjectStore('ShipmentData', { keyPath: 'id' });
        objectStore.createIndex('shipment_id', 'shipment_id', {
          unique: true,
        });
      }
    }
  };

  request.onsuccess = function (event) {
    console.log(event);
  };
};

export const transactionIndexDB = ({ action, collection, item }) => {
  const request = idb.open('shakaWear-db', 1);
  request.onerror = function (event) {
    console.error(event, 'onerror');
  };
  request.onsuccess = function (event) {
    let db = request.result;
    if (event.type === 'success') {
      if (action === 'create') {
        let tx = db.transaction(collection, 'readwrite').objectStore(collection).add(item);
        popup(POPUP_TYPE.SUCCESS, POPUP_MSG.DATABASE_CREATED);
        return tx.complete;
      } else if (action === 'update') {
        let tx = db.transaction(collection, 'readwrite').objectStore(collection).put(item);
        return tx.complete;
      } else if (action === 'delete') {
        let tx = db.transaction(collection, 'readwrite').objectStore(collection).delete(item.id);
        return tx.complete;
      }
    }
  };
};
