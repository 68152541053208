import React from 'react';
import PropTypes from 'prop-types';

const DESCSortIcon = ({ handleClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      style={{ cursor: 'pointer' }}>
      <path
        d="M11.2263 13.4428V4.36426"
        stroke="#17B1A4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9447 10.7119L11.2262 13.443L8.50769 10.7119"
        stroke="#17B1A4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.60731 2.55469V11.6332"
        stroke="#738DA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.88892 5.2858L4.60743 2.55469L7.32595 5.2858"
        stroke="#738DA6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

DESCSortIcon.propTypes = {
  handleClick: PropTypes.func,
};

export default DESCSortIcon;
