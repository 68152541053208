import React from 'react';
const Swap = ({ width, height, className, handleClick }) => (
  <svg
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    onClick={handleClick}
    style={{ cursor: 'pointer' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      d="M11.2263 13.4428V4.36426"
      stroke="#738DA6"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9448 10.7119L11.2263 13.443L8.50781 10.7119"
      stroke="#738DA6"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.60743 2.55469V11.6332"
      stroke="#738DA6"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.88892 5.2858L4.60743 2.55469L7.32595 5.2858"
      stroke="#738DA6"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Swap;
