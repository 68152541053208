import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const getPelletList = async ({ data = {} }) => {
  const url = API.PELLET_LIST;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const receptionReport = async ({ data = {} }) => {
  const url = API.RECEPTION_REPORT;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const createGrid = async ({ data = {} }) => {
  const url = API.CREATE_GRID;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const startPelleting = async ({ data = {} }) => {
  const url = API.START_PELLETING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const getPrint = async ({ data = {} }) => {
  const url = API.PRINT_PALLET_START_PALLETING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const pelletPackages = async ({ data = {} }) => {
  const url = API.PELLET_PACKAGES;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const packagePlacing = async ({ data = {} }) => {
  const url = API.PACKAGE_PLACING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const palletTransfer = async ({ data = {} }) => {
  const url = API.PALLET_TRANSFER;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const finishPelleting = async ({ data = {} }) => {
  const url = API.FINISH_Pelleting;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
