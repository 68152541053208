import React from 'react';
import {  Delete } from '../../../assets/icons';
import { SHIPMENT_TILE } from '../../../constants/constant';

export const ShipmentTile = ({ tile, onRemove }) => {
  return (
    <div className="col-xl-2 col-lg-4 col-6 custom-col">
      <div className="w-100 h-100 bg-white tiles">
        <div
          className={`w-100 d-flex align-items-center justify-content-center ${tile.tileClass || ''}`}>
          <span className="id-info">
            {SHIPMENT_TILE[0]}
            <span>{tile.name}</span>
          </span>
        </div>
        <div className="w-100 d-flex align-items-center justify-content-between main-content-box">
          <div className='d-flex flex-column gap-2 w-75'>
          <span className="tile-details">
            {SHIPMENT_TILE[1]}
            <span>{tile.lot_name}</span>
          </span>
          <span className="tile-details">
            {SHIPMENT_TILE[2]}
            <span>{tile.sku}</span>
          </span>
          </div>
          <div className="img-box delete" onClick={onRemove}>
            <Delete />
          </div>
          {tile.Batch && tile.SKU && (
            <div className="sku-number flex-column d-flex align-items-start justify-content-start">
              <h6 className="m-0">
                {SHIPMENT_TILE[1]}
                <span>{tile.SKU}</span>
              </h6>
              <p className="m-0">
                {SHIPMENT_TILE[2]} {tile.Batch}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
