import React from 'react';
const NotVerified = (props) => (
  <svg
    fill="#000000"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    id="more-circle-vertical"
    data-name="Line Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon line-color"
    {...props}>
    <line
      id="secondary-upstroke"
      x1={12}
      y1={6.95}
      x2={12}
      y2={7.05}
      style={{
        fill: 'none',
        stroke: 'rgb(44, 169, 188)',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 2,
      }}
    />
    <line
      id="secondary-upstroke-2"
      data-name="secondary-upstroke"
      x1={12}
      y1={11.95}
      x2={12}
      y2={12.05}
      style={{
        fill: 'none',
        stroke: 'rgb(44, 169, 188)',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 2,
      }}
    />
    <line
      id="secondary-upstroke-3"
      data-name="secondary-upstroke"
      x1={12}
      y1={16.95}
      x2={12}
      y2={17.05}
      style={{
        fill: 'none',
        stroke: 'rgb(44, 169, 188)',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 2,
      }}
    />
    <circle
      id="primary"
      cx={12}
      cy={12}
      r={9}
      style={{
        fill: 'none',
        stroke: 'rgb(199, 173, 90)',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 2,
      }}
    />
  </svg>
);
export default NotVerified;
