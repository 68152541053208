import React from 'react';
import { ROUTES } from '../../../constants';
import { Button } from '../../shared';
import { useNavigate } from 'react-router-dom';

export const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div className="error-page h-100 mb-5">
      <div className="container h-100 ">
        <div className="row h-100 ">
          <div className="col-md-12  h-100">
            <div className="error-template h-75 d-flex flex-column align-items-center justify-content-center">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">
                Sorry, an error has occurred, Requested page not found!
              </div>
              <div className="error-actions d-flex gap-3">
                <Button
                  clickHandler={() => navigate(ROUTES.HOME)}
                  className={
                    'border blue-btn d-flex align-items-center justify-content-center py-2 px-3'
                  }>
                  Take Me Home
                </Button>

                <Button
                  className={
                    'border white-btn d-flex align-items-center justify-content-center py-2 px-3'
                  }>
                  Contact Support
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
