import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import {
  Login,
  DashboardContent,
  Shipment,
  PelletList,
  Transfers,
  Deliveries,
  ShipmentDetail,
  DeliveryDetail,
  PelletDetails,
  Pelleting,
  TransferSelector,
  Error404,
} from './components/pages';
import { ROUTES } from './constants';
import { Layout } from './components/common/Layout';
import { getLocalStorageItem } from './utils';
import CreateTransfer from './components/pages/transfers/CreateTransfer';

const VendorAuthGuard = ({ children }) => {
  const authToken = getLocalStorageItem('auth_token');
  return authToken === null ? <Navigate to={ROUTES.LOGIN} replace /> : children;
};
const LoginGuard = ({ children }) => {
  const authToken = getLocalStorageItem('auth_token');
  return authToken ? <Navigate to={ROUTES.DASHBOARD} replace /> : children;
};

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: (
      <LoginGuard>
        <Login />
      </LoginGuard>
    ),
  },

  {
    path: ROUTES.HOME,
    element: <Layout />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: (
          <VendorAuthGuard>
            <DashboardContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.SHIPMENT,
        element: (
          <VendorAuthGuard>
            <Shipment />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.SHIPMENT_DETAIL}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <ShipmentDetail />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.PELLETING,
        element: (
          <VendorAuthGuard>
            <PelletList />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.PELLETING}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <PelletDetails />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.PELLET_DETAILS}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <Pelleting />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.TRANSFERS,
        element: (
          <VendorAuthGuard>
            <Transfers />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.TRANSFER_DETAIL}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <TransferSelector />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.CREATE_TRANSFER}`,
        element: (
          <VendorAuthGuard>
            <CreateTransfer />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.DELIVERIES,
        element: (
          <VendorAuthGuard>
            <Deliveries />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.DELIVERY_DETAIL}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <DeliveryDetail />
          </VendorAuthGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
