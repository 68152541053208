//Button texts
export const BUTTON = {
  SCAN_LOCATION: 'Scan Location',  
  SCAN_SOURCE_LOCATION: 'Scan Source Location',
  SCAN_DESTINATION_LOCATION: 'Scan Destination Location',
  LOAD_STOCK: 'Load Stock',
  REMOVE_STOCK: 'Remove Stock',
  IMMEDIATE_TRANSFER: 'Immediate Transfer',
  IMMEDIATE_UNPACK: 'Immediate Unpack',
  CREATE_TRANSFER: 'Create Transfer',
  SUBMIT: 'Submit',
  Back: 'Back',
  SCAN_BOX: 'Scan Box',
  STOP_SCAN: 'Stop Scan',
  SignIn: 'Sign In',
  CLOSE: 'Close',
  
  CONFIRM_DELIVERY: 'Confirm Delivery Order',
  ConfirmTransferOrder: 'Confirm Transfer Order',
  CREATE_PACKAGE: 'Create Package',
  PRINT_LABEL: 'Print Label',
  PRINT_QR: 'Print QR',
  SEARCH: 'Search',
  CLEAR_FILTERS: 'Clear Filters',
  UPDATE: 'Update',
  CONFIRM: 'Confirm',
  GRID: 'Create Grid',
  SHOW_GRID: 'Show Grid',
  START_PALLETING: 'Start Palleting',
  RESUME_PALLETING: 'Resume Palleting',
  CANCEL: 'Cancel',
  CLEAR: 'Clear Scan',
  PLACE:'Move',
  PRINT_UPS_LABEL: 'Print UPS Label',
  UPDATE_SHOPIFY_STATUS: 'Update Shopify Status',
  PRINT_PACKING_LIST: 'Print Packing List',
  OUTGOING_DELIVERY_SCAN: 'Outgoing Delivery Scan',
  ADD_ROW: 'Add Row',
  MARK_AS_TODO: 'Mark as Todo',
  VALIDATE: 'Validate',
  FINALIZE_PICK: 'Finalize Pick'
};

export const POP = {
  BILL_CREATED: 'Bill Created in Draft Mode',
  ALL_BOXES_SCANNED: 'All boxes scanned in this shipment',
  SKU_ADDED: 'Package added successfully',
  FILL_ALL_SKU_DETAIL: 'Please fill all details of SKU',
};


// Routes
export const ROUTES = {
  HOME: '/',
  EMPTY: '',
  LOGIN: '/login',
  SERVER_ERROR: '/server_error',
  DASHBOARD: '/dashboard',
  SHIPMENT: '/shipment',
  SHIPMENT_DETAIL: '/shipment_detail',
  PELLETING: '/palleting',
  CONFIGURATION: '/pre_palleting',
  PELLET_DETAILS: '/pallet_details',
  TRANSFERS: '/transfers',
  TRANSFER_DETAIL: '/transfer_detail',
  CREATE_TRANSFER:'/create_transfer',
  DELIVERIES: '/deliveries',
  DELIVERY_DETAIL: '/delivery_detail',
  URL: '/:url',
  NULL: '/',
};

// methods
export const METHODS = {
  UPDATE: 'UPDATE',
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH',
};

// headers
export const HEADERS = {
  APPLICATION_JSON: 'application/json',
  AUTHORIZATION: 'Bearer',
};

// API Endpoints
export const API = {
  WAREHOUSE_LOGIN: '/login',
  WAREHOUSE_LOGOUT: '/logout',
  WAREHOUSE_TRANSFERS: '/transfers',
  WAREHOUSE_TRANSFERS_BY_SCAN: '/fetch/transfer/by_package_name',
  WAREHOUSE_TRANSFERS_QTY_UPDATE: '/transfer/move/update',
  WAREHOUSE_PACKAGE_SCAN: '/delivery/package/scan',
  WAREHOUSE_SHIPMENT: '/shipment',
  WAREHOUSE_APPROVE_SHIPMENT: '/manufacture/approve/shipment',
  PELLET_LIST: '/palleting',
  PELLET_PACKAGES: '/pallet/packages',
  RECEPTION_REPORT: '/reception/report',
  FINISH_Pelleting: '/validate/picking',
  WAREHOUSE_DELIVERY: '/delivery',
  WAREHOUSE_VALIDATE_PICKING: '/validate/picking',
  WAREHOUSE_CHECK_UNCHECK: '/status_checked',
  CREATE_GRID: '/pallet/grid_data/structure',
  PLACE_PACKAGE: '/placed/verified/pallets',
  START_PELLETING: '/start/pallet_process',
  PRINT_PALLET_START_PALLETING: '/print/tile/qr_code',
  PACKAGE_PLACING: '/placed/verified/pallets',
  PALLET_TRANSFER: '/pallet/confirm_palleting',
  PRINT_PACKING_LIST_DELIVERY: '/print/packing_list/report',
  PRINT_UPS_LABEL: '/delivery/generate_ups_labels',
  UPDATE_SHOPIFY_STATUS: '/shopify_status/update',
  PRINT_LABEL_DELIVERY: '/delivery/print_label',
  OUTGOING_SCAN_DELIVERY: '/delivery/datetime_store',
  GET_DELIVERY_PACKAGE : '/get/create_delivery_package',
  SUBMIT_PACKAGE:'/create_delivery_package',
  CREATE_TRANSFER: '/wt/create',
  SCAN_LOCATION: '/wt/scan/location',
  LOAD_STOCK: '/wt/load_stock',
  REMOVE_STOCK: '/wt/remove_stock',
  IMMEDIATE_TRANSFER: '/wt/immediate_transfer',
  IMMEDIATE_UNPACK: '/wt/immediate_unpack',
  MARK_AS_TODO_WT: '/wt/mark_as_to_do',
  UPDATE_PHYSICAL_QTY_WT: '/wt/physical_quantity/update',
  PACKAGE_SCAN_LOAD_STOCK_WT: '/wt/mark/package/available',
  PACKAGE_SCAN_WT: '/wt/scan/package',
  FINALIZE_PICK: '/delivery/finalize'
};

// Status Codes
export const HTTP_CODES = { INVALID_TOKEN: 498, INTERNAL_SERVER_ERROR: 500, OK: 200 };

// Tiles
export const TILES = {
  SHIPMENT: 'Shipment',
  Pelleting: 'Palleting',
  TRANSFERS: 'Transfers',
  DELIVERIES: 'Deliveries',
};

// Titles
export const TITLES = {
  DASHBOARD: 'Dashboard',
  SHIPMENT: 'Shipment',
  DELIVERY: 'Deliveries',
  TRANSFER: 'Transfers',
  PALLET_TRANSFER: 'Pallet Transfer',
  WHT: 'Warehouse Transfer',
  PELLETING: `Palleting`,
  CONFIGURE: 'Config',
  CREATE_PACKAGE:'Create Package'
};

// Local Storage
export const LOCAL_STORAGE = {
  AUTH_TOKEN: 'auth_token',
  ALLOWED_APP: 'allowed_apps',
  TAGS: 'tags',
  USERNAME: 'username',
};

export const VALIDATION = {
  CREDENTIAL_MISMATCH: 'Email ID or Password does not Match',
};

export const ERROR = {
  INVALID_LOCAL_STORAGE_KEY_OR_VALUE: 'Invalid Key value Pair',
};

// Shipment Strings
export const SHIPMENT_DETAIL_BOX = [
  'Shipment No',
  'Shipment Reference',
  'Partner Name',
  'Package Count',
  'Scheduled Date',
  'Deadline Date',
  'Status',
];

export const SHIPMENT_TILE = ['BLP-', 'SKU- ', 'Batch- '];

export const SHIPMENT_STATUS = [
  {
    name: 'Approved Box',
    className: 'dark-green',
  },
  {
    name: 'Repetitive Box',
    className: 'maron',
  },
  {
    name: 'Duplicate Box',
    className: 'bright-orange',
  },
];

export const SHIPMENT_PACKAGE = ['Scanned Boxes', 'Box Count'];

export const POPUP_TYPE = {
  ERR: 'error',
  SUCCESS: 'success',
};

export const POPUP_MSG = {
  RE_SCANNED: "Scan Repeated",
  SCAN_PACKAGE_FIRST: 'Package not scanned to be placed on Pallet',
  ENTER_QTY: 'Enter Physically Available Quantity',
  INVALID_QTY: 'Entered Quantity is Invalid',
  WRONG_INPUT: 'Wrong data input',
  INDEX_DB_NOT_SUPPORTED: "This browser doesn't support IndexedDB",
  DATABASE_CREATED: 'Offline mode Enabled',
  OFFLINE_MODE_DISABLED: 'Offline mode is not available',
  FILL_ALL_ROWS: 'Please fill all the row details to proceed with submission',
  ALREADY_PLACED: 'Package is already placed on the pallet',
  ALREADY_VERIFIED: 'Package is already Verified',
  WRONG_PALLET_SCANNED: 'Wrong Pallet scanned',
  PLACED_AND_VERIFIED: 'Package is verified and placed on the pallet',
  WRONG_SCAN: ' Invalid code scanned',
  WRONG_PACKAGE_SCANNED: 'Wrong or Moved Package Scanned',
  STATUS_CHECKED_FOR_ALL: 'Location Verified',
  WRONG_LOCATION_SCANNED: 'Wrong Location Scanned',
  TILE_NOT_AVAILABLE: 'Tile not available',
  PALLET_NOT_INCLUDED: 'Pallet is not available in Grid',
  PACKAGE_ALREADY_SCANNED: 'Package already scanned',
  PACKAGE_PLACED: (pack, action, palletName) => {
    return `${pack} ${action} on  ${palletName}`;
  },
  PACKAGE_VERIFIED: (pack) => {
    return `${pack} Verified`;
  },
  PALLET_TRANSFERRED: (pallet) => {
    return `${pallet} Transferred`;
  },
};

export const STATUS = {
  FAIL: 'fail',
  PASS: 'pass',
  READY: 'ready',
  CUT: 'cut',
  SEW: 'sew',
  DYE: 'dye',
  PACK: 'pck',
};

export const DB = 'shaka_db_17_10';

export const FORM_FIELD = {
  EMAIL: 'Email',
  PASSWORD: 'Password',
  SIGN_UP: 'Sign up',
  LOG_OUT: ' Log Out',
};

export const TEXT = {
  NO_ACCOUNT: 'Don’t have an account?',
  NO_LOGOUT: 'Unable to Logout at this moment',
};

export const HEADING = {
  DETAIL_OPERATIONS: 'Detailed Operations',
  OPERATIONS: 'Operations',
  SCAN_BARCODE: 'Scan a Barcode/QR',
  PACKAGES: 'Packages',
  SELECT_SCAN_MODE: 'Please select scan mode',
};

export const SCANOPTIONS = {
  SCAN_GUN: 'Scan Gun',
  CAMERA: 'Camera'
}

export const SORTING = {
  DEFAULT: 'Default',
  ASCENDING: 'Asc',
  DESCENDING: 'Dsc',
};