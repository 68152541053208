import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Header, Sidebar } from './index.js';
import { ROUTES } from '../../constants';

export const Layout = () => {
  const [currentDashboardRoute, setCurrentDashboardRoute] = useState(ROUTES.DASHBOARD);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === ROUTES.HOME) navigate(ROUTES.DASHBOARD);
    setCurrentDashboardRoute(location.pathname);
    if (location.pathname === ROUTES.LOGIN) navigate(ROUTES.DASHBOARD);
  }, [location.pathname]);

  return (
    <main className="main-page-area">
      <Header />
      <div className="main-view-area d-flex align-items-start justify-content-start position-relative">
        <Sidebar
          currentDashboardRoute={currentDashboardRoute}
          setCurrentDashboardRoute={setCurrentDashboardRoute}
        />
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default Layout;
