import React from 'react';
const Right = (props) => (
  <svg
    width={28}
    height={26}
    viewBox="0 0 28 26"
    fill="#52B02C"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14 26C21.3737 26 27.3514 20.1797 27.3514 13C27.3514 5.8203 21.3737 0 14 0C6.62625 0 0.648647 5.8203 0.648647 13C0.648647 20.1797 6.62625 26 14 26Z"
      fill="#52B02C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0869 16.7106L7.97945 13.5258C7.55402 13.0898 7.57844 12.3999 8.03377 11.9926C8.4891 11.5852 9.20949 11.6086 9.63492 12.0446L12.0319 14.5012L15.8259 11.1066C15.86 11.076 15.8957 11.048 15.9325 11.0223L17.7167 9.426C18.172 9.01862 18.8925 9.04206 19.3179 9.47809C19.7433 9.91404 19.7188 10.604 19.2635 11.0113L13.8142 15.887L13.8083 15.881L11.9233 17.5676L11.0869 16.7103V16.7106Z"
      fill="white"
    />
  </svg>
);
export default Right;
