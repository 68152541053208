import { Login } from './login/Login';
import { Logout } from './login/Logout';
import { DashboardContent } from './dashBoard/DashboardContent';
import { PelletList } from './pelleting/PelletList';
import { PelletDetails } from './pelleting/PelletDetails';
import { Pelleting } from './pelleting/Pelleting';
import { Transfers } from './transfers/Transfers';
import { Deliveries } from './deliveries/Deliveries';
import { Shipment } from './shipment/Shipment';
import { ShipmentDetail } from './shipment/ShipmentDetail';
import { TransfersDetail } from './transfers/TransfersDetail';
import { TransfersDetailPalletTransfer } from './transfers/TrasfersDetailPalletTransfer';
import { DeliveryDetail } from './deliveries/DeliveryDetail';
import { TransferSelector } from './transfers/TransferSelector';
import { WHTDetail } from './transfers/WHTDetail';
import { Error404 } from './Error/Error';

export {
  Login,
  Logout,
  DashboardContent,
  Shipment,
  ShipmentDetail,
  PelletList,
  PelletDetails,
  Transfers,
  TransferSelector,
  TransfersDetail,
  TransfersDetailPalletTransfer,
  WHTDetail,
  Deliveries,
  DeliveryDetail,
  Pelleting,
  Error404,
};
