import React, { useState } from 'react';
import { AlertCross, AlertInfo } from '../../assets/icons';
import { Button } from '../shared';

export const AlertBox = ({ heading }) => {
  const [isVisible, setIsVisible] = useState(true);


  if (!isVisible) {
    return null;
  }

  return (
    <div className="alert-box yellow-alert mb-3">
      <div className="d-flex align-items-start justify-content-start box-area">
        <div className="d-flex align-items-center justify-content-start box-txt-area">
          <AlertInfo />
          <div className="text">
            <h6 className="mb-0">
              Shipment is {heading?.charAt(0).toUpperCase() + heading?.slice(1)}
            </h6>
            <p className="mb-0">
              Shipment is in {heading?.charAt(0).toUpperCase() + heading?.slice(1)} Phase
            </p>
          </div>
        </div>
        <Button
          className="border-0 cross-icon p-0 d-flex align-items-start justify-content-start"
          clickHandler={() => setIsVisible(false)}>
          <AlertCross />
        </Button>
      </div>
    </div>
  );
};
