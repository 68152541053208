import React from 'react';
import { PelletIcon, Verified } from '../../assets/icons';

export const Pellet = ({ selectedPellet, setSelectedPellet, tile, doneStatus, setPopUp }) => {
  const completed = tile?.pellet?.packages_count === tile?.pellet?.packages?.length;
  const popUpHandler = () => {
    setPopUp(true);
    setSelectedPellet(tile?.pellet?.pellet_id);
  };

  return (
    <>
      {tile?.pellet?.pellet_id ? (
        <div>
          <div onClick={popUpHandler} className="custom-box h-100 w-100 mb-3">
            <div
              className={`w-100 ${selectedPellet?.pellet_id === tile?.pellet?.pellet_id ? 'highlighted-shadow' : ' '}  h-100 bg-white pellet-tiles bg-md`}>
              <div
                className={`top-box ${selectedPellet?.pellet_id === tile?.pellet?.pellet_id ? 'highlighted' : completed || doneStatus ? 'green-box' : 'blue-box'} text-center w-100`}>
                {`${tile?.display_name}`}
              </div>
              <div
                className={`box-bottom p-0 h-100 ${selectedPellet?.pellet_id === tile?.pellet?.pellet_id ? 'highlighted-bottom' : completed || doneStatus ? 'dark-green' : 'dark-blue'} w-100 d-flex align-items-center justify-content-center`}>
                <div className="txt-box h-100 d-flex align-items-center justify-content-between">
                  <div className="number-txt ps-3">
                    {completed || doneStatus
                      ? `${tile?.pellet?.packages_count}/${tile?.pellet?.packages_count}`
                      : `${tile?.pellet?.packages.length}/${tile?.pellet?.packages_count}`}
                  </div>
                  <div>{!tile?.pellet?.verified ? <PelletIcon /> : <Verified />}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="custom-box ">
            <div
              className={` text-nowrap empty-tiles bg-md ${selectedPellet?.tile?.display_name === tile?.display_name ? 'highlighted' : 'bg-body-secondary'}`}>
              {tile?.display_name}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
