export const deliveryDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let deliveryData = {
      url: obj.name.replaceAll('/', '_'),
      reference: obj.name || 'N/A',
      from: obj.location_id?.display_name || 'N/A',
      to: obj.location_dest_id?.display_name || 'N/A',
      scheduled_date: obj.scheduled_date.split(' ')[0],
      source_document: obj.origin || 'N/A',
      status: obj.state.toUpperCase(),
      data: obj,
    };
    finalData.push(deliveryData);
  });
  return finalData;
};

export function deliveryDetailBox(data) {
  let detailBoxData = [
    { name: 'Contact', col: 1, data: data?.partner_id?.name || data?.user_id?.name|| 'N/A' },
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.display_name || 'N/A' },
    { name: 'Packing Preference', col: 1, data: data?.packing_preference || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Deadline', col: 2, data: data?.date_deadline || 'N/A' },
    { name: 'Source Document', col: 2, data: data?.origin || 'N/A' },
  ];
  return detailBoxData;
}
export const innerDeliveryOperationsCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let deliveryOperationData = {
      product: obj.product_id?.display_name,
      demand: obj.product_uom_qty,
      quantity: obj.quantity,
      unit: obj.product_uom?.name,
    };
    finalData.push(deliveryOperationData);
  });
  return finalData;
};

export const innerDeliveryDetailedOperationCompiler = (data) => {
  let finalData = [];
  if (Array.isArray(data.move_line_ids) && data.move_line_ids.length > 0) {
    data.move_line_ids.forEach((moveLine) => {
      let innerDeliveryData = {
        move_line_id: moveLine.id,
        product: moveLine.product_id?.display_name,
        create_delivery_package: data?.create_delivery_package,
        from: moveLine.location_id?.display_name,
        to: moveLine.location_dest_id?.display_name,
        blpName: moveLine.result_package_id?.name || 'N/A',
        checked: moveLine.checked,
        scan_picked: moveLine.scan_picked,
        reserve_quantity: moveLine.initial_reserve_qty,
        done_quantity: moveLine.done_qty,
        model: data.model,
        data: moveLine,
      };
      finalData.push(innerDeliveryData);
    });
  } 
  // // else if (Array.isArray(data.move_ids) && data.move_ids.length > 0) {
  // //   data.move_ids.forEach((move) => {
  // //     let innerDeliveryData = {
  // //       product: move.product_id?.display_name,
  // //       from: move.location_id?.display_name,
  // //       to: move.location_dest_id?.display_name,
  // //       blpName: move.packages_ids?.length ? move.packages_ids[0].name : 'N/A',
  // //       checked: false,
  // //       scan_picked: false,
  // //       reserve_quantity: move.product_uom_qty,
  // //       done_quantity: move.quantity || 0,
  // //       status: false,
  // //       model: data.model,
  // //       data: move,
  // //     };
  // //     finalData.push(innerDeliveryData);
  // //   });
  // }

  return finalData;
};
