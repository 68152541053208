import DashboardIcon from './DashboardIcon';
import Arrow from './Arrow';
import Search from './Search';
import Reference from './Reference';
import Sort1 from './Sort1';
import BoxIcon from './BoxIcon.js';
import EyeIcon from './EyeIcon.js';
import ErrorIcon from './ErrorIcon.js';
import ShipmentLogo from './ShipmentLogo.js';
import PelletingLogo from './PelletingLogo.js';
import TransferLogo from './TransferLogo.js';
import DeliveryLogo from './DeliveryLogo.js';
import AlertInfo from './AlertInfo.js';
import AlertCross from './AlertCross.js';
import ScanIcon from './ScanIcon.js';
import DropDownArrow2 from './DropDownArrow2.js';
import OpenEyeIcon from './OpenEyeIcon.js';
import EditIcon from './EditIcon.js';
import PelletIcon from './PelletIcon.js';
import Right from './Right.js';
import Delete from './Delete.js';
import Close from './Close.js';
import YellowRight from './YellowRight.js';
import Reset from './Reset.js';
import NotVerified from './NotVerified.js';
import Verified from './Verified.js';
import Swap from './Swap.js';
import ASCSortIcon from './ASCSortIcon.js';
import DESCSortIcon from './DESCSortIcon.js';
import DeleteRedIcon from './DeleteRedIcon.js';

export {
  Swap,
  DESCSortIcon,
  ASCSortIcon,
  Search,
  OpenEyeIcon,
  DropDownArrow2,
  ScanIcon,
  AlertCross,
  AlertInfo,
  ShipmentLogo,
  PelletingLogo,
  TransferLogo,
  DeliveryLogo,
  DashboardIcon,
  Arrow,
  Reference,
  Sort1,
  BoxIcon,
  EyeIcon,
  ErrorIcon,
  EditIcon,
  PelletIcon,
  Right,
  Close,
  Delete,
  YellowRight,
  Reset,
  NotVerified,
  Verified,
  DeleteRedIcon,
};
