import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { TitleBar } from './TitleBar';
import { SearchBar } from './SearchBar';
import { Table } from './Table';
import { ErrorBoundary } from './ErrorBoundary';
import { DropDown } from './DropDown';
import { Loader } from './Loader';
import { DropDownInput } from './DropDownInput';
import { DetailBox } from './DetailBox';
import { AlertBox } from './AlertBox';
import { Pellet } from './Pellet';
import { Scanner } from './Scanner';
import { PopUpBox } from './PopupBox';
export {
  PopUpBox,
  AlertBox,
  Header,
  Sidebar,
  TitleBar,
  DropDownInput,

  SearchBar,
  Table,
  ErrorBoundary,
  DropDown,
  Loader,
  DetailBox,
  Pellet,
  Scanner,
};
