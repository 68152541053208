import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TitleBar, PopUpBox, Scanner } from '../../common';
import { TITLES, ROUTES, POPUP_TYPE, POPUP_MSG, BUTTON } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailsBoxPellet } from './DetailsBoxPellet';
import { useDebounce } from '../../../hooks';
import { pelletPackages, packagePlacing, palletTransfer, createGrid } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { ZoomInOut } from './ZoomInOut';
import { PalletingFlow, packageVerification, gridDataConvertor } from './PalletingFunction';
import { Button } from '../../shared';

export const Pelleting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [count, setCount] = useState(0);
  const [packageData, setPackageData] = useState([]);
  const [pelletData, setPelletData] = useState({});
  const [scanData, setScanData] = useState('');
  const [selectedPellet, setSelectedPellet] = useState({});
  const [popUp, setPopUp] = useState(false);
  const [reload, setReload] = useState(false);
  const [packageScanned, setPackageScanned] = useState([]);
  const [tileData, setTileData] = useState([]);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [gridData, setGridData] = useState({
    rows: 0,
    pallets: [],
    pallets_count: 0,
  });

  const assignPallets = (packages) => {
    if (packages.every((obj) => obj.state === 'moved')) {
      navigate(ROUTES.PELLETING);
      return;
    }
    const state = ['assigned', 'verified', 'placed'];
    let availablePackages = packages.filter((pack) => state.includes(pack.state));
    let unique = [...new Set(availablePackages.map((pack) => pack.pallet_id?.id))];

    const data = unique.map((id) => {
      return {
        pellet_id: id,
        packages: packages
          .filter(
            (pack) =>
              pack.pallet_id.id === id && (pack.state === 'placed' || pack.state === 'verified'),
          )
          .map((pack) => {
            return { id: pack.id, verified: pack.state, blp: pack.package_id.name };
          }),
        packages_count: packages.filter((pack) => pack.pallet_id.id == id).length,
        pellet_name: packages.filter((pack) => pack.pallet_id.id == id)[0].pallet_id.name,
        SKU: packages.filter((pack) => pack.pallet_id.id == id)[0].product_id.default_code,
        verified: packages
          .filter((pack) => pack.pallet_id.id === id)
          .every((pack) => pack.state === 'verified'),
      };
    });
    setPelletData({
      packageCount: packages.length,
      palletsCount: unique.length,
      pellets: data,
    });
    setCount(packages.filter((pack) => pack.state !== 'assigned').length);
  };

  useEffect(() => {
    if (pelletData?.pellets?.length) {
      if (gridData?.pallets?.length) {
        let tile = 0;
        setTileData(
          gridData.pallets
            .map((pal, index) => {
              return pal.list.reduce((acc, cum, ind) => {
                tile++;
                acc.push({
                  display_name: `Tile ${tile}`,
                  rowIndex: index,
                  palletIndex: ind,
                  pellet:
                    pelletData.pellets.filter((pellet) => pellet.pellet_id === cum.pallet_id)[0] ||
                    false,
                });
                return acc;
              }, []);
            })
            .flat(),
        );
      }
    }
  }, [pelletData]);

  const getPelletPackages = useDebounce(() => {
    pelletPackages({
      data: { shipment_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setGridData(response.data.grid_data);
        if (response.data.pallets.length) {
          setPackageData(response.data.pallets);
          assignPallets(response.data.pallets, response.data.grid_data.pallets_count);
          setReload(false);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getPelletPackages();
      return;
    }
    dispatch(setLoaderVisibility(true));
    getPelletPackages();
  }, [reload]);

  useEffect(() => {
    if (scanData.startsWith('000084')) {
      const pallet = packageData?.filter((pack) => pack.package_id.name === scanData);
      if (pallet.length > 0 && pallet[0].state != 'moved') {
        if (
          tileData.map((tile) => tile.pellet?.pellet_id).includes(pallet[0].pallet_id.id) ||
          tileData.filter((tile) => !tile.pellet).length
        ) {
          if (
            packageScanned.length > 0 &&
            packageScanned[0].pallet_id === pallet[0].pallet_id.id &&
            packageScanned.every((pack) => pack.state === 'assigned') &&
            pallet[0].state === 'assigned' &&
            !packageScanned.map((pack) => pack.package).includes(scanData)
          ) {
            setPackageScanned((pre) => [
              ...pre,
              {
                id: pallet[0].id,
                state: pallet[0].state,
                pallet_id: pallet[0].pallet_id.id,
                package: scanData,
              },
            ]);
          }
        }
      }
    }
    PalletingFlow({
      scanData,
      packageData,
      pelletData,
      setScanData,
      setPackageScanned,
      packageScanned,
      setSelectedPellet,
      dispatch,
      setReload,
      tileData,
      setLoaderVisibility,
      gridData,
      selectedPellet,
      state,
      setConfirmPopUp,
    });
  }, [scanData]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PELLETING, route: ROUTES.PELLETING },
          { text: TITLES.CONFIGURE, route: `${ROUTES.PELLETING}/${state?.name}` },
          { text: state?.container_id || state?.name, route: '' },
        ]}
      />
      <div className="pellet-scroll h-100 ">
        <DetailsBoxPellet
          doneStatus={state?.pallet_process_state === 'done'}
          packageData={packageData}
          packageCount={packageData.packages_count}
          scanData={scanData}
          state={state}
          tileData={tileData}
          setScanData={setScanData}
          pelletData={pelletData}
          gridData={gridData}
          setGridData={setGridData}
          count={count}
          popUp={popUp}
          setReload={setReload}
          selectedPellet={selectedPellet}
          setSelectedPellet={setSelectedPellet}
          setPackageScanned={setPackageScanned}
          packageScanned={packageScanned}
        />
        <ZoomInOut
          gridData={gridData}
          tileData={tileData}
          pelletData={pelletData}
          packageData={packageData}
          setPopUp={setPopUp}
          popUp={popUp}
          count={count}
          setSelectedPellet={setSelectedPellet}
          doneStatus={state?.pallet_process_state === 'done'}
          selectedPellet={selectedPellet}
        />
        {popUp && (
          <PopUpBox openPopUpBox={popUp}>
            <BLPList
              setSelectedPellet={setSelectedPellet}
              selectedPellet={selectedPellet}
              setPopUp={setPopUp}
              id={state?.id}
              tileData={tileData}
              pelletData={pelletData}
              setReload={setReload}
              gridData={gridData}
              packageData={packageData}
            />
          </PopUpBox>
        )}
        {confirmPopUp && (
          <PopUpBox openPopUpBox={confirmPopUp}>
            <ConfirmationBox
              selectedPellet={selectedPellet}
              setConfirmPopUp={setConfirmPopUp}
              id={state?.id}
              packageScanned={packageScanned}
              setSelectedPellet={setSelectedPellet}
              setPackageScanned={setPackageScanned}
              setReload={setReload}
              state={state}
              gridData={gridData}
              setScanData={setScanData}
            />
          </PopUpBox>
        )}
      </div>
    </>
  );
};

const ConfirmationBox = ({
  setSelectedPellet,
  setPackageScanned,
  setConfirmPopUp,
  selectedPellet,
  packageScanned,
  setReload,
  state,
  gridData,
  setScanData,
}) => {
  const dispatch = useDispatch();
  const movePackage = () => {
    dispatch(setLoaderVisibility(true)),
      packagePlacing({
        data: {
          pallet_process_ids: packageScanned.map((pck) => pck.id),
          type: 'placed',
        },
      })
        .then((response) => {
          setConfirmPopUp(false);
          popup(
            POPUP_TYPE.SUCCESS,
            POPUP_MSG.PACKAGE_PLACED(
              packageScanned.length < 2
                ? packageScanned[0].package
                : `${packageScanned.length} package`,
              response.data[0].state,
              selectedPellet.tile.display_name,
            ),
          ),
            setPackageScanned([]),
            setReload(true),
            createGrid({
              data: {
                shipment_id: state?.id,
                grid_data: gridDataConvertor(gridData), // Send the entire updated gridData here
              },
            }).then(() => {
              setSelectedPellet({});
              setScanData('');
            });
        })
        .catch((error) => {
          dispatch(setLoaderVisibility(false));
          popup(POPUP_TYPE.ERR, error.message);
        });
  };
  const cancelHandler = () => {
    setConfirmPopUp(false);
    setSelectedPellet({});
    setPackageScanned([]);
  };
  return (
    <div className="p-3 ">
      <h4 className=" text-wrap">
        {`You have Multiple package to Move on ${selectedPellet?.tile?.display_name}.`}{' '}
      </h4>
      <h6>Do you want to Proceed?</h6>
      <hr />
      <div className=" d-flex flex-column gap-1">
        {packageScanned.map((pack, index) => {
          return (
            <div key={index} className=" bg-body-secondary d-flex justify-content-around">
              <span>{index + 1}.</span>
              <span>{pack.package}</span>
            </div>
          );
        })}
      </div>

      <hr />
      <div className=" d-flex justify-content-end">
        <div className="w-75 d-flex gap-5 justify-content-around">
          <Button
            className="blue-btn w-100 d-flex align-items-center justify-content-center px-4 py-2"
            clickHandler={movePackage}>
            {BUTTON.PLACE}
          </Button>
          <Button
            className="blue-btn w-100 d-flex align-items-center justify-content-center px-4 py-2"
            clickHandler={cancelHandler}>
            {BUTTON.CANCEL}
          </Button>
        </div>
      </div>
    </div>
  );
};
const BLPList = ({
  id,
  setReload,
  pelletData,
  selectedPellet,
  setPopUp,
  setSelectedPellet,
  tileData,
  packageData,
}) => {
  const [scanData, setScanData] = useState('');
  const [status, setStatus] = useState(0);
  const dispatch = useDispatch();
  const [popUpPellet, setPopUpPellet] = useState([]);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);

  const handleTransfer = () => {
    if (!status) {
      setStatus(1);
    } else {
      dispatch(setLoaderVisibility(true)),
        palletTransfer({
          data: {
            pallet_id: popUpPellet.pellet_id,
            shipment_id: id,
          },
        })
          .then((response) => {
            setReload(true);
            setPopUp(false);
            popup(POPUP_TYPE.SUCCESS, response.message);
          })
          .catch((error) => {
            dispatch(setLoaderVisibility(false));
            popup(POPUP_TYPE.ERR, error.message);
          });

      setPopUp(false);
    }
  };

  const handleClose = () => {
    setPopUp(false), setSelectedPellet({});
  };

  const statusHandler = () => {
    setStatus((pre) => (pre === 0 ? 1 : 0));
  };
  useEffect(() => {
    if (scanData) {
      if (
        (scanData.startsWith('000084')|| scanData.startsWith('PACK')) &&
        popUpPellet.packages.map((pack) => pack.blp).includes(scanData)
      ) {
        if (popUpPellet.packages.filter((pack) => pack.blp === scanData)[0].verified === 'placed') {
          packageVerification({
            dispatch,
            setLoaderVisibility,
            process_ids: popUpPellet.packages.filter((pack) => pack.blp === scanData)[0]?.id,
            setReload,
            msg: POPUP_MSG.PACKAGE_VERIFIED(scanData),
            setPopUpPellet,
            pelletData,
            selectedPellet,
            setScanData,
            apiCall: packagePlacing,
          });
        } else {
          popup(POPUP_TYPE.ERR, POPUP_MSG.ALREADY_VERIFIED);
        }
      } else if (
      (scanData.startsWith('000084')|| scanData.startsWith('PACK')) &&
        packageData.filter((pack) => pack.package_id.name === scanData)[0]?.pallet_id?.id ===
          popUpPellet?.pellet_id
      ) {
        packageVerification({
          dispatch,
          setLoaderVisibility,
          process_ids: packageData.filter((pack) => pack.package_id.name === scanData)[0].id,
          setReload,
          msg: POPUP_MSG.PLACED_AND_VERIFIED,
          setPopUpPellet,
          pelletData,
          selectedPellet,
          setScanData,
          apiCall: packagePlacing,
        });
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_SCAN);
        setScanData('');
      }
    }
  }, [scanData]);
  useEffect(() => {
    if (popUpPellet?.packages?.every((pack) => pack.verified === 'verified')) {
      setStatus(1);
    }
  }, [popUpPellet]);
  useEffect(() => {
    if (typeof selectedPellet === 'number') {
      setPopUpPellet(pelletData.pellets.filter((pal) => pal.pellet_id == selectedPellet)[0]);
    }
  }, [selectedPellet, pelletData]);

  return (
    <div className='h-100'>
      <div>
        <div className="d-flex flex-column align-items-end justify-content-end">
          <h3 className="w-100 text-center">
            {tileData.filter((tile) => tile.pellet.pellet_id === selectedPellet)[0].display_name}
          </h3>
          <hr className="w-100" />
          <div className="d-flex w-100 justify-content-between ">
            <h4>SKU-{popUpPellet.SKU}</h4>
            <h4>{`${popUpPellet?.packages?.length}/${popUpPellet?.packages_count}`}</h4>
          </div>
        </div>
        <div className=" d-flex mb-3 justify-content-between border">
          <div
            onClick={statusHandler}
            className={`${!status ? 'bg-success-subtle' : ''} w-50 text-center p-2 border `}>
            Verification Step
          </div>
          <div
            onClick={statusHandler}
            className={`${status ? 'bg-success-subtle' : ''} w-50 text-center p-2 border `}>
            Transfer Pallet
          </div>
        </div>
      </div>
      <div className="fixed-height">
        {' '}
        {popUpPellet?.packages?.length ? (
          popUpPellet?.packages?.map((blp, index) => {
            return (
              <div key={index}>
                <div
                  className={`${blp?.verified === 'verified' ? 'bg-success-subtle' : ' not-verified'} d-flex  w-100 align-items-center justify-content-around p-2 m-1`}>
                  <h5>{index + 1}. </h5>
                  <h5 className="w-50 ">{blp?.blp}</h5>
                </div>
              </div>
            );
          })
        ) : (
          <div className=" d-flex flex-column align-items-center  justify-content-center">
            {'No packages found'}
          </div>
        )}
      </div>

      <hr></hr>

      <div className="d-flex justify-content-around">
        {popUpPellet?.packages?.length > 0 && (
          <>
            <div className=" d-flex justify-content-center visually-hidden">
              {isCamScannerOpen && (
                <Scanner
                  setScanData={setScanData}
                  isCamScannerOpen={isCamScannerOpen}
                  setIsCamScannerOpen={setIsCamScannerOpen}></Scanner>
              )}{' '}
            </div>
            <Button
              clickHandler={handleTransfer}
              className="blue-btn d-flex align-items-center justify-content-center px-5 py-4">
              {!status ? 'Skip' : 'Transfer'}
            </Button>
          </>
        )}
        <Button
          clickHandler={handleClose}
          className="blue-btn d-flex align-items-center justify-content-center px-5 py-4">
          {BUTTON.CLOSE}
        </Button>
      </div>
    </div>
  );
};
