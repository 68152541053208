import React from 'react';
const PelletIcon = (props) => (
  <svg
    width={28}
    height={27}
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8604 26.5C21.2341 26.5 27.2117 20.6797 27.2117 13.5C27.2117 6.3203 21.2341 0.5 13.8604 0.5C6.48661 0.5 0.509003 6.3203 0.509003 13.5C0.509003 20.6797 6.48661 26.5 13.8604 26.5Z"
      fill="#049CBD"
    />
    <g clipPath="url(#clip0_1254_20881)">
      <path
        d="M19.777 14.4063C19.6693 14.4063 19.5659 14.3635 19.4898 14.2873C19.4136 14.2111 19.3708 14.1078 19.3708 14C19.3708 13.009 19.077 12.0403 18.5265 11.2163C17.9759 10.3922 17.1934 9.75001 16.2778 9.37076C15.3622 8.99151 14.3548 8.89228 13.3828 9.08564C12.4108 9.27899 11.518 9.75623 10.8173 10.457C10.6493 10.6252 10.4933 10.8049 10.3504 10.9949C10.2857 11.0811 10.1895 11.1381 10.0828 11.1533C9.97618 11.1685 9.86784 11.1408 9.78164 11.0761C9.69545 11.0115 9.63846 10.9152 9.62323 10.8086C9.60799 10.7019 9.63575 10.5936 9.70039 10.5074C9.86664 10.2877 10.0476 10.0796 10.2421 9.88446C11.0563 9.06993 12.0937 8.51512 13.2232 8.29022C14.3527 8.06531 15.5236 8.1804 16.5877 8.62093C17.6518 9.06146 18.5613 9.80764 19.2014 10.7651C19.8414 11.7226 20.1831 12.8484 20.1833 14C20.1833 14.1078 20.1405 14.2111 20.0643 14.2873C19.9881 14.3635 19.8848 14.4063 19.777 14.4063Z"
        fill="white"
      />
      <path
        d="M14.3603 19.8229C13.5956 19.8231 12.8384 19.6725 12.1318 19.38C11.4253 19.0874 10.7833 18.6585 10.2426 18.1177C9.70187 17.577 9.27296 16.935 8.98038 16.2285C8.6878 15.522 8.53729 14.7647 8.53743 14C8.53743 13.8923 8.58023 13.7889 8.65642 13.7127C8.7326 13.6366 8.83594 13.5938 8.94368 13.5938C9.05142 13.5938 9.15476 13.6366 9.23094 13.7127C9.30713 13.7889 9.34993 13.8923 9.34993 14C9.34987 14.991 9.6437 15.9598 10.1942 16.7838C10.7448 17.6078 11.5273 18.25 12.4429 18.6293C13.3585 19.0085 14.3659 19.1078 15.3379 18.9144C16.3099 18.7211 17.2027 18.2438 17.9034 17.543C18.0713 17.3751 18.2271 17.1956 18.3698 17.0057C18.4365 16.9256 18.5315 16.8743 18.6351 16.8622C18.7386 16.8501 18.8428 16.8783 18.9262 16.9408C19.0097 17.0034 19.0659 17.0956 19.0833 17.1983C19.1007 17.3011 19.0779 17.4067 19.0198 17.4932C18.8537 17.7132 18.6727 17.9215 18.4781 18.1167C17.9386 18.6591 17.2969 19.0893 16.5901 19.3821C15.8833 19.675 15.1254 19.8248 14.3603 19.8229Z"
        fill="white"
      />
      <path
        d="M18.9564 19.0023C18.8486 19.0023 18.7453 18.9595 18.6691 18.8833C18.5929 18.8071 18.5501 18.7038 18.5501 18.596V17.4705H17.424C17.3163 17.4705 17.2129 17.4277 17.1367 17.3515C17.0606 17.2753 17.0178 17.172 17.0178 17.0642C17.0178 16.9565 17.0606 16.8531 17.1367 16.7769C17.2129 16.7008 17.3163 16.658 17.424 16.658H18.9564C19.0641 16.658 19.1675 16.7008 19.2436 16.7769C19.3198 16.8531 19.3626 16.9565 19.3626 17.0642V18.596C19.3626 18.7038 19.3198 18.8071 19.2436 18.8833C19.1675 18.9595 19.0641 19.0023 18.9564 19.0023Z"
        fill="white"
      />
      <path
        d="M11.2961 11.342H9.76431C9.65657 11.342 9.55324 11.2992 9.47705 11.223C9.40086 11.1468 9.35806 11.0435 9.35806 10.9358V9.40393C9.35806 9.29619 9.40086 9.19286 9.47705 9.11667C9.55324 9.04048 9.65657 8.99768 9.76431 8.99768C9.87206 8.99768 9.97539 9.04048 10.0516 9.11667C10.1278 9.19286 10.1706 9.29619 10.1706 9.40393V10.5295H11.2961C11.4039 10.5295 11.5072 10.5723 11.5834 10.6485C11.6596 10.7247 11.7024 10.828 11.7024 10.9358C11.7024 11.0435 11.6596 11.1468 11.5834 11.223C11.5072 11.2992 11.4039 11.342 11.2961 11.342Z"
        fill="white"
      />
      <path
        d="M17.0394 13.7459L17.8021 12.4746L14.0895 10.2451L13.3236 11.5164C13.1914 11.797 13.126 12.1044 13.1325 12.4145C13.139 12.7247 13.2172 13.0291 13.361 13.3039C13.3659 13.3187 13.3675 13.3345 13.3658 13.35C13.364 13.3655 13.3589 13.3805 13.3508 13.3939C13.3428 13.4073 13.3319 13.4187 13.319 13.4275C13.306 13.4363 13.2913 13.4422 13.2759 13.4447C12.9657 13.4472 12.6603 13.5215 12.3836 13.6618C12.1069 13.8021 11.8665 14.0046 11.6813 14.2535L10.9186 15.5253L14.6339 17.7548L15.3971 16.4835C15.5292 16.2029 15.5947 15.8955 15.5883 15.5854C15.5819 15.2753 15.5039 14.9709 15.3603 14.696C15.3554 14.6812 15.3537 14.6655 15.3554 14.65C15.3571 14.6345 15.3622 14.6196 15.3702 14.6062C15.3782 14.5928 15.389 14.5813 15.4019 14.5725C15.4148 14.5637 15.4294 14.5578 15.4448 14.5552C15.755 14.5526 16.0604 14.4781 16.3371 14.3377C16.6138 14.1973 16.8542 13.9948 17.0394 13.7459Z"
        fill="white"
      />
      <path
        d="M18.1656 11.8568L14.4519 9.62949C14.1956 9.47573 13.8631 9.55891 13.7093 9.81528C13.5555 10.0717 13.6387 10.4041 13.8951 10.5579L17.6088 12.7852C17.8651 12.939 18.1976 12.8558 18.3514 12.5994C18.5052 12.3431 18.422 12.0106 18.1656 11.8568Z"
        fill="white"
      />
      <path
        d="M14.8256 17.4296L11.1119 15.2022C10.8556 15.0485 10.5231 15.1317 10.3693 15.388C10.2155 15.6444 10.2987 15.9769 10.5551 16.1307L14.2688 18.358C14.5251 18.5118 14.8576 18.4286 15.0114 18.1722C15.1652 17.9158 15.082 17.5833 14.8256 17.4296Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1254_20881">
        <rect width={13} height={13} fill="white" transform="translate(7.86035 7.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default PelletIcon;
