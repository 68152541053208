export const shipmentDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let shipmentData = {
      url: obj?.name.replaceAll('/', '_'),
      shipment_no: obj?.name,
      package_count: obj?.packages_count,
      container: obj?.container_id || "N/A",
      partner_name: obj?.partner_id?.name,
      scheduled_date: obj?.scheduled_date.split(' ')[0],
      status: obj?.state.toUpperCase(),
      data: obj,
    };
    finalData.push(shipmentData);
  });
  return finalData;
};
