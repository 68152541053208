import React, { useEffect, useState } from 'react';
import { DropDown, SearchBar, Table, TitleBar } from '../../common';
import { BUTTON, POPUP_TYPE, ROUTES, SHIPMENT_COLUMN, TITLES } from '../../../constants';
import { shipmentDataCompiler } from '../../../utils/data_compiler/shipmentCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { getSortingArray, popup } from '../../../utils';
import { warehouseShipment } from '../../../service';
import { Button } from '../../shared';

export const Shipment = () => {
  const dispatch = useDispatch();
  const [shipmentData, setShipmentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(SHIPMENT_COLUMN[6].name);
  const [sortingArray1, setSortingArray1] = useState([]);

  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        container_id: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getShipmentList(data);
    } else if (!searchTerm && !Array.isArray(shipmentData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getShipmentList(data);
    }
  }, [search, searchTerm]);

  const resetFilter = () => {
    setSort1(SHIPMENT_COLUMN[6].name);
    dispatch(setLoaderVisibility(true));
    getShipmentList();
  };

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getShipmentList();
    }
  }, [searchTerm]);

  useEffect(() => {
    let data = {};
    if (sortingArray1.length > 1) {
      data = {
        state: `${sortingArray1.includes(sort1) ? (sort1.toLowerCase() === 'n/a' ? false : sort1.toLowerCase()) : ''}`,
      };
      dispatch(setLoaderVisibility(true));
      getShipmentList(data);
    }
  }, [sort1]);

  // API Calling
  const getShipmentList = useDebounce((data = {}) => {
    warehouseShipment({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setShipmentData(response.data);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: SHIPMENT_COLUMN[6],
                data: response.data,
                compiler: shipmentDataCompiler,
              }),
            );
          }
        } else {
          setShipmentData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getShipmentList();
  }, []);

  return (
    <>
      <TitleBar title={[{ text: TITLES.SHIPMENT, route: ROUTES.SHIPMENT }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          {sortingArray1.includes(sort1) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(shipmentData) ? (
        <Table
          columns={SHIPMENT_COLUMN}
          initialData={shipmentData}
          PAGE_SIZE={10}
          colFilter={false}
          to={ROUTES.SHIPMENT_DETAIL}
          compiler={{ use: true, tool: shipmentDataCompiler }}
        />
      ) : (
        <div>
          <h4 className="px-1 text-center py-5">{shipmentData}</h4>
        </div>
      )}
    </>
  );
};
