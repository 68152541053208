import React from 'react';
import { Search, Close } from '../../assets/icons';
import PropTypes from 'prop-types';
import { Button, Input } from '../shared';
import { BUTTON } from '../../constants';

export const SearchBar = ({ setSearch, searchTerm, setSearchTerm }) => {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const searchHandler = () => {
    setSearch(true);
  };
  const clearSearch = () => {
    setSearchTerm('');
  };
  return (
    <div className="mb-3">
      <div className="container-search-bar w-100 d-flex align-items-center gap-2">
        <div className="bg-white position-relative d-flex align-items-center w-100 justify-content-start searchbar-area">
          <div className="search-btn2 position-absolute border-0">
            <Search />
          </div>
          <Input
            type="text"
            className="border-0 w-100 d-flex align-items-center justify-content-center"
            placeholder={''}
            value={searchTerm}
            onChange={handleInputChange}
          />
          {searchTerm && (
            <div onClick={clearSearch} className="search-btn2 border-0">
              <Close />
            </div>
          )}
        </div>
        <Button
          clickHandler={searchHandler}
          className={`search-btn d-flex align-items-center ${searchTerm===undefined || searchTerm?.length===0 ? 'disabled' : ''} justify-content-center px-3`}
          disabled={searchTerm===undefined || searchTerm?.length===0}>
          {BUTTON.SEARCH}
        </Button>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
};
