import * as React from 'react';
const PelletingLogo = (props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 53.25H72V60.75H8V53.25Z" fill="#4B5564" />
    <path
      d="M66.25 64.5H58.75V68.25H43.75V64.5H36.25V68.25H21.25V64.5H13.75V68.25H8V72H72V68.25H66.25V64.5Z"
      fill="#4B5564"
    />
    <path d="M15.625 49.5H38.125V30.625H28.75V38.125H25V30.625H15.625V49.5Z" fill="#4B5564" />
    <path d="M64.375 30.625H55V38.125H51.25V30.625H41.875V49.5H64.375V30.625Z" fill="#4B5564" />
    <path d="M28.75 26.875H51.25V8H41.875V15.5H38.125V8H28.75V26.875Z" fill="#4B5564" />
  </svg>
);
export default PelletingLogo;
