import React from 'react';
import { EditIcon } from '../../../assets/icons';

export const ActionBoxWT = ({
  on_hand_quantity,
  state,
  move_line_id,
  physically_available_quantity,
  setPopUp,
  setPopUpData,
}) => {
  return (
    <div className="td-num">
      {(
        state==='draft'?
        <EditIcon
          onClick={(e) => {
            e.stopPropagation();
            setPopUp(1);
            setPopUpData({ on_hand_quantity, physically_available_quantity, move_line_id });
          }}
        />:'-'
      )}
    </div>
  );
};
